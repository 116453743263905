import moment from 'moment';
import { CallData } from '../Views/Common/CallPanel/types';
import { Lead } from '../Views/Leads/types';
import { leadInHouseStatuses } from '../Views/Telemarketer/constants';

type UseCallAttemptsParams = {
    calls: CallData[];
    lead: Lead;
    containActiveCall?: boolean;
};

const NUMBER_OF_ATTEMPTS = 8;

export function useCallAttempts(params: UseCallAttemptsParams) {
    const calls = params?.calls || [];
    const callsCount = params?.calls?.length || 0;
    const activeCall = calls?.find(
        (x) =>
            !!x?.userStartedDialingAt && !moment(x?.callEndedAt || '').isValid()
    );
    const activeCallIndex = activeCall
        ? calls?.indexOf(activeCall)
        : callsCount;
    const isFinalStatus =
        params?.lead?.inHouseStatus === leadInHouseStatuses.CONVERTED ||
        params?.lead?.inHouseStatus === leadInHouseStatuses.LOST;
    const maxNumberOfAttempts =
        params?.lead?.Funnel?.numberOfCalls || NUMBER_OF_ATTEMPTS;
    const callAttempts = [...Array(maxNumberOfAttempts).keys()].map((i) => {
        const isLast = i === calls.length - 1;

        return {
            callNumber: i + 1,
            statusChange:
                isFinalStatus && isLast
                    ? params?.lead?.inHouseStatus
                    : undefined,
            isAnswered: calls[i]?.isAnswered || false,
            isAttempted: moment(calls[i]?.callEndedAt || '').isValid(),
            isCurrent: i === activeCallIndex && !!params?.containActiveCall,
        };
    });

    return { callAttempts };
}
