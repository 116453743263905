import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks';

const RedirectRoute = () => {
    const { checkUserRole } = useAuth();

    if (checkUserRole(['telemarketer', 'telemarketer_admin'])) {
        return (
            <Navigate to={{ pathname: '/telemarketer/new-leads' }} replace />
        );
    }

    return <Navigate to={{ pathname: '/dashboard' }} replace />;
};

export default RedirectRoute;
