import { User, UserRoleTypes } from '../Views/Auth/types';
import { useAppSelector } from '../store/hooks';

export function useAuth() {
    const authenticatedUser = useAppSelector((state) => state.user.user);

    const checkUserRole = (roles: UserRoleTypes[], user?: User) => {
        const userToBeChecked = user || authenticatedUser;

        if (!userToBeChecked?.Role?.RoleName) {
            return false;
        }

        return roles.includes(userToBeChecked?.Role?.RoleName);
    };

    return { checkUserRole };
}
