import { LeadInHouseStatuses } from '../types';

const leadInHouseStatuses: Record<LeadInHouseStatuses, LeadInHouseStatuses> = {
    CONVERTED: 'CONVERTED',
    FOLLOW_UP: 'FOLLOW_UP',
    LOST: 'LOST',
    NEW: 'NEW',
} as const;

export { leadInHouseStatuses };
